.nav-link {
  color: #ffffff;
  text-decoration: none;
}

.navbar_link {
  position: relative;
  text-decoration: none;
}
.navbar_link:hover {
  color: #ffc000 !important;
}

.active {
  color: #ffc000 !important;
}
.active::before {
  content: "";
  position: absolute;
  left: 30%;
  text-align: center;
  bottom: -4px;
  width: 20px; /* or 100px */
  border: 2px solid #ffc000;
  border-radius: 10px;
}

@media only screen and (max-width: 992px) and (min-width: 270px) {
  .active::before {
    content: "";
    position: absolute;
    left: 30%;
    text-align: center;
    bottom: -4px;
    width: 20px; /* or 100px */
    border: 0px solid red;
    border-radius: 10px;
  }

  .navbar_link {
    position: relative;
    text-decoration: none;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
  }
}
