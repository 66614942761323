@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant&family=Poppins&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Ghost Clan";
  src: url(/static/media/GhostClan.666cf5b6.ttf) format("truetype");
}

@font-face {
  font-family: "Myriad Pro";
  src: url(/static/media/MyriadPro-Regular.75eddee0.ttf)
    format("truetype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* colors in website */
.color_white {
  color: #ffffff;
}

.color_golden {
  color: #ffc000;
}
.Color_1D1D1B {
  color: #1d1d1b;
}
.bg_color_black {
  background-color: #000000;
}
/* Fonts Myriad Pro */

.Myriad_normal_18_ {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px !important;
  line-height: 26px;
}

.Myriad_normal_20_,
.Myriad_normal_20_ > h4 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal !important;
  font-size: 20px !important;
  line-height: 30px !important;
}

h6 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 25px !important;
  /* identical to box height, or 104% */

  letter-spacing: 0.346154px;

  color: #ffffff;
}

/* Fonts Ghost Pro */

.Ghost_normal_normal {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal !important;
  font-size: 54px !important;
  line-height: 47px;
}

.Ghost_normal_80 > h2 {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal;
  font-size: 80px;
  line-height: 90px;
  /* identical to box height, or 112% */

  letter-spacing: 0.888889px;

  /* S Orange */

  color: #ffc000;
}

.footer_heading > h4 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 37px;
  color: #ffffff;
}

.footer_description > h5 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  /* or 162% */

  /* Color / Gray 4 */

  color: #f8f8f8;

  opacity: 0.85;
}
.Ghost_normal_100px > h3 {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal;
  font-size: 100px;
  line-height: 87px;
  color: #000000;
  margin: 0px !important;
}
/* Margins in Website */

.margin_right_28 {
  margin-right: 28px;
}
.margin_right_56 {
  margin-right: 56px;
}

.margin_left_56 {
  margin-left: 56px;
}

#apply_now_btn {
  background: #ffc000;
  border: 2px solid #000000;
  box-sizing: border-box;
  padding: 18px 52px 17px 52px;

  font-family: Myriad Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  transform: skew(-21deg, -1deg);
  box-shadow: 10px 10px #000000;
  /* margin-top: 40px;
  box-shadow: 12px 13px 1px 4px rgba(0, 0, 0, 0.98);  */
}
#apply_second_btn {
  transform: skew(21deg, 1deg);

  font-family: Myriad Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  background: #ffc000;
  border: none;
  outline: none;
}
.footer_map {
  margin-top: 88px;
}
/* Resposive margin in website */

@media only screen and (max-width: 768px) {
  .margin_right_56 {
    margin-right: 16px;
  }

  .margin_left_56 {
    margin-left: 16px;
  }
}

@media only screen and (max-width: 470px) and (min-width: 270px) {
  .about_section_hero_section {
    text-align: center;
  }
}

.nav-link {
  color: #ffffff;
  text-decoration: none;
}

.navbar_link {
  position: relative;
  text-decoration: none;
}
.navbar_link:hover {
  color: #ffc000 !important;
}

.active {
  color: #ffc000 !important;
}
.active::before {
  content: "";
  position: absolute;
  left: 30%;
  text-align: center;
  bottom: -4px;
  width: 20px; /* or 100px */
  border: 2px solid #ffc000;
  border-radius: 10px;
}

@media only screen and (max-width: 992px) and (min-width: 270px) {
  .active::before {
    content: "";
    position: absolute;
    left: 30%;
    text-align: center;
    bottom: -4px;
    width: 20px; /* or 100px */
    border: 0px solid red;
    border-radius: 10px;
  }

  .navbar_link {
    position: relative;
    text-decoration: none;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
  }
}

.Hompage-slider-wrapper {
  width: 99vw;
  height: 88vh;
  overflow: hidden;
  background: #000000;
}

.slider-Image-wrapper > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  position: relative;
}
.Hompage-slider-container {
  position: relative;
  left: 0;
  top: 0;
}
.prev {
  cursor: pointer;
}
.prev > button {
  background-color: transparent;
  border: none;
}
.next {
  cursor: pointer;
}

.next > button {
  background-color: transparent;
  border: none;
}
.Homepage_arrows {
  width: 100vw;
  position: absolute;
  top: 48%;
  z-index: 10;
}

@media only screen and (max-width: 768px) {
  .slider-Image-wrapper > img {
    width: 100%;
    height: 30vh;
    object-fit: cover;

    overflow: hidden;
  }
  .Hompage-slider-wrapper {
    width: 100%;
    height: 30vh;
    overflow: hidden;
    background: #000000;
  }
}

.Homepgae_start_website {
  background: #000000;
}
.homePage-container {
  position: relative;
  margin-top: -8.6%;
  width: 100%;
  z-index: 99999;
  transform: skewY(-3.5deg);
  background: #000000;
}

.HomePage_Who_text > h2 {
  transform: skewY(3.5deg);
  display: flex;

  align-items: center;

  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal;
  font-size: 54px;
  line-height: 47px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  /* S Orange */

  color: #ffc000;
}
.HomePage_Who_text > p {
  transform: skewY(3.5deg);
  display: flex;

  align-items: center;
}
.padding_top_bottom {
  padding-top: 244.5px;
  padding-bottom: 244.5px;
}
.who_img_padding_top {
  padding-top: 100.5px;
  position: relative;
}
#my_video {
  padding-left: 138px;
  transform: skewY(3.5deg);
  width: 50vw;
  height: 70vh;

  object-fit: cover;
}
.homePage_bg_pic {
  background-image: url("https://aurilian.s3.us-east-2.amazonaws.com/compresspng+(5)/img-min.png");
  /* background-color: black; */
  height: 800px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* transform: skewY(-3.5deg); */
  margin-top: -64px;
}

/* ===========================HomePage Bg====================== */

.HomePage_Who_text_second > h2 {
  display: flex;
  align-items: center;
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal;
  font-size: 54px;
  line-height: 47px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  /* S Orange */

  color: #ffc000;
}
.HomePage_Who_text_second > p {
  display: flex;
  align-items: center;
}

.padding_top_bottom_second {
  padding-top: 275.5px;
  padding-bottom: 275.5px;
}

.counting_number_bg {
  height: 337px;
  background: #000000;
  transform: skewY(-3.5deg);
  position: relative;
  margin-top: -3.7%;
}

.Join_Us {
  position: relative;
  padding-top: 228px;
  padding-bottom: 228px;
  background: #ffc000;
  transform: skewY(-3.5deg);
  padding-top: 162px;
}

.join_us_common {
  transform: skewY(3.5deg);
}

.join_us_heading {
  margin-bottom: 24px;
}

.join_us_desc {
  margin-bottom: 40px;
}
.cod_bg {
  width: 100%;
  height: 577px;
  background-image: url("https://aurilian.s3.us-east-2.amazonaws.com/compresspng+(5)/Footer-min.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -65px;
  background-color: black;
}

/* .who_img_padding_top > img {
  width: 100vw;
  height: 88vh;
  object-fit: fill;
  overflow: hidden;
  position: relative;
} */
/* 
transform: matrix(-1, 0, 0, 1, 0, 0); */

.counting_numbers_Wrapper {
  width: 100%;
  padding-top: 106px;
  padding-bottom: 106px;
  background: #000000;
  margin-top: -60px;
  position: relative;
  transform: skewY(-3.5deg);
}

.counting_numbers_container {
  width: 100%;
  padding-top: 106px;
  padding-bottom: 106px;
  transform: skewY(3.5deg);
}

@media screen and (max-width: 1200px) {
  .padding_top_bottom {
    padding-top: 122px;
    padding-bottom: 122px;
  }
}

@media screen and (max-width: 992px) {
  .padding_top_bottom {
    padding-top: 61px;
    padding-bottom: 8px;
  }

  .who_img_padding_top {
    padding-top: 20px;
    padding-bottom: 40px;
  }
  #my_video {
    padding-left: 70px;
    transform: skewY(3.5deg);
    width: 70vw;
    height: 70vh;

    object-fit: cover;
  }
}

@media screen and (max-width: 768px) {
  .who_img_padding_top > img {
    padding-left: 16px;
    padding-bottom: 40px;
  }
  .padding_top_bottom {
    padding-top: 40px;
    padding-bottom: 16px;
  }

  .who_img_padding_top {
    padding-top: 16px;
    position: relative;
  }
  .HomePage_Who_text > p {
    padding: 16px;
  }
  .HomePage_Who_text_second > p {
    display: flex;
    align-items: center;
    padding: 0px 16px;
  }
  .Join_Us {
    padding-top: 16px;
  }

  .Join_Us {
    position: relative;
    height: auto;
    background: #ffc000;
  }

  .homePage_bg_pic {
    background-color: black;
    height: auto;
  }

  .who_img_padding_top {
    padding-top: 20px;
    padding-bottom: 40px;
  }
  #my_video {
    padding-left: 70px;
    transform: skewY(3.5deg);
    width: 70vw;
    height: 50vh;

    object-fit: cover;
  }
}

@media screen and (max-width: 576px) {
  .who_img_padding_top {
    padding-top: 20px;
    padding-bottom: 40px;
  }
  #my_video {
    padding-left: 4px;
    transform: skewY(3.5deg);
    width: 90vw;
    height: 40vh;

    object-fit: cover;
  }

  .Join_Us {
    position: relative;
    padding-top: 228px;
    padding-bottom: 228px;
    background: #ffc000;
    transform: skewY(-3.5deg);
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .margin_left_number {
    margin-left: -10%;
  }
}

@media screen and (min-width: 1920) {
  #my_video {
    padding-left: 70px;
    transform: skewY(3.5deg);
    width: 70vw;
    height: 50vh;

    object-fit: cover;
  }
}

a {
  text-decoration: none;
  color: white;
}
.footer_main_wrapper {
  width: 100%;
  /* height: 547px; */
  background: #000000;
}
.footer_second_col_li > a {
  text-decoration: none;
  color: white;
}

.footer_second_col_li > a:hover {
  text-decoration: none;
  color: white;
}
.footer_second_col_li > a > li {
  list-style-type: none;
}
.footer_heading {
  margin-top: 97px;
  margin-bottom: 16px;
}
.footer_description {
  margin-bottom: 65px;
}
.footer_fb_icon {
  margin-right: 20px;
}
.footer_fb_icon {
  cursor: pointer;
}

.caraousel_image_text {
  margin-left: 90px;
}
.footer_second_col_list {
  margin-top: 97px;
  margin-bottom: 24px;
}
.footer_second_col_list > h5 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  opacity: 0.5;
}

.footer_second_col_li {
  padding-left: 0px;
}
.footer_second_col_li > li {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;

  list-style-type: none;
  margin-bottom: 9px;
}
.footer_list > li {
  margin-bottom: 11px;
}
.footer_img {
  margin-top: 88px;
  margin-bottom: 160px;
  width: 80%;
}
.img-fluid {
  width: 70%;
}

.modal-header {
  border-bottom: 0px !important ;
}

.privacy_modal_header {
  margin-bottom: 24px;
}

.privacy_modal_header > h2 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 35px;
  /* identical to box height, or 125% */

  text-align: center;

  color: #000000;
  margin-bottom: 0px !important;
}

.modal-fullscreen .modal-content {
  border-radius: 35px;
}

.privacy_modal_text > p,
.privacy_modal_text > li {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 35px;
  /* or 175% */

  color: #000000;
}

.collection_of_data > h4 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  /* identical to box height, or 125% */

  color: #000000;
}

.footer_bootom {
  display: flex;
  justify-content: space-around;
}

.footer_bootom > li {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  /* Color / White */

  color: #ffffff;
  text-decoration: none;
  list-style-type: none;
  cursor: pointer;
}

.footer_right_reserved > h4 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.subscribe_modal_img {
  background-image: url("https://aurilian.s3.us-east-2.amazonaws.com/compresspng+(4)/subscribe-min-min.png");
  width: 100%;
  height: 422px;
  overflow-x: hidden;
}

.m_subscribe_text > h3 {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 55px;

  color: #000000;
}

.m_subscribe_para > p {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  color: #000000;
}

.subscribe_modal_align {
  margin-top: 84px;
  margin-right: 24px;
}

.m_subscribe_para_input > input {
  width: 70%;
  height: 58px;
  background: #f6f6f6;
  border-radius: 5px;
  border: none;
  padding-left: 16px;
}

.m_subscribe_para_input > button {
  background: #ffc000;
  border-radius: 0px 5px 5px 0px;
  border: none;
  padding: 12px 16px 12px 16px;
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  color: #000000;
}

.close_btn_img > img {
  margin-top: 29px;
  margin-right: 25px;
}

@media only screen and (max-width: 470px) and (min-width: 270px) {
  .footer_bootom {
    display: block;
    text-align: center;
  }

  .footer_bootom > li {
    margin-bottom: 16px;
  }
  .caraousel_image_text {
    margin-left: 50px;
  }
  .footer_description {
    margin-bottom: 16px;
  }
  .img-fluid {
    width: 100%;
  }
  .img-fluid {
    width: 100%;
  }
  ul {
    padding-left: 0px !important;
  }
  .footer_img {
    margin-bottom: 120px;
  }
}

.about_us_page_main_wrapper {
  overflow: hidden;
}
.about_section_hero_main_wrapper {
  padding-top: 122px;
  padding-bottom: 123px;
  background: #000000;
}
.about_section_hero_section > h1 {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal;
  font-size: 84px;
  line-height: 73px;
  background: linear-gradient(180deg, #fedd22 5.56%, #f4913f 89.29%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.about_hero_img_section {
  position: relative;
}
.about_hero_img_sec {
  position: absolute;
  top: -122px;
  left: 100px;
}

.abput_hero_bg {
  position: absolute;
  top: -80px;
  left: 155px;
}
.about_us_first {
  margin-top: 167px;
}

.about_us_first > img {
  width: 100%;
  height: 100%;
}

.about_us_second {
  margin-top: 0px;
}

.about_us_second_ > img {
  height: 70%;
  width: 70%;
}

.about_us_second > img {
  width: 100%;
  height: 100%;
}
.about_story_far {
  margin-bottom: 42px;
}
.about_story_far_second {
  margin-bottom: 24px;
}
.about_story_far_second > h2 {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 42px;
  color: #000000;
  margin-bottom: 0px !important;
}
.about_story_far > h2 {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 42px;
  color: #000000;
  margin-bottom: 0px !important;
}

.about_story_far_third {
  margin-bottom: 30px;
}
.about_story_far_third > h2 {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 42px;
  color: #000000;
  margin-bottom: 0px !important;
}

.about_ever_since > p {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 35px;
  /* or 159% */

  display: flex;
  align-items: center;

  color: #000000;
  margin-bottom: 0px !important;
}

.about_section_text_wrapper {
  margin-top: 329px;
  margin-bottom: 179px;
}

.about_section_text_wrapper_second {
  margin-top: 249px;
  margin-bottom: 250px;
}

.about_section_text_wrapper_third {
  margin-top: 228px;
  margin-bottom: 232px;
}

.about_yellow_line {
  height: 40px;
  background: #ffc000;
  margin-bottom: 80px;
}

.about_us_values_sec {
  margin-bottom: 16px;
}

.about_us_values_sec > h3 {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 42px;
  text-align: center;
  text-transform: uppercase;

  color: #000000;
  margin-bottom: 0px !important;
}

.about_us_values_sec-para > p {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 35px;
  text-align: center;
  color: #000000;
  margin-bottom: 0px !important;
}
.about_us_values_sec-para {
  margin-bottom: 64px;
}

.about_us_elements_haeding {
  margin-bottom: 16px;
}

.about_us_elements_haeding > h4 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  color: #000000;
  margin-bottom: 0px !important;
}

.about_us_elements_para > p {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 35px;
  color: #000000;
  margin-bottom: 0px !important;
}

.about_elements_img > img {
  position: relative;
  z-index: 9999;
  background: white;
}
.about_elements_img > div {
  transform: rotate(90deg);
  text-align: center;
}
.about_elements_img > div {
  text-align: center;
  border-bottom: 4px solid transparent;
  border-image: linear-gradient(0.25turn, #fedd22, #f4913f);
  border-image-slice: 1;
  width: 100%;
}

.about_element_main_wrapper {
  padding-bottom: 80px;
}

.element_bottome_yellow_line {
  height: 40px;
  background: #ffc000;
  margin-bottom: 80px;
}

.ceo_message_about > h4 {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 42px;

  text-transform: uppercase;

  color: #000000;
}

.ceo_message_about_para {
  margin-top: 32px;
  margin-bottom: 32px;
}

.ceo_message_about_para > p {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 35px;

  text-align: left;

  color: #000000;
}

.ceo_about_eleement > h4 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 35px;
  text-align: center;

  color: #000000;
  margin-bottom: 0px !important;
}

.ceo_img_padding {
  margin-bottom: 24px;
}
@media only screen and (max-width: 1200px) and (min-width: 992px) {
  .about_hero_img_sec {
    position: absolute;
    top: -122px;
    left: 75px;
  }
  .abput_hero_bg {
    position: absolute;
    top: -80px;
    left: 117px;
  }
  .about_us_first {
    margin-top: 280px;
  }
  .about_us_second {
    margin-top: 120px;
  }
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
  .about_section_hero_main_wrapper {
    height: 90vh;
  }
  .about_hero_img_sec {
    position: absolute;
    top: 0px;
    left: 18%;
  }
  .abput_hero_bg {
    position: absolute;
    top: 40px;
    left: 26%;
  }
  .about_section_text_wrapper {
    margin-top: 180px;
    margin-bottom: 1px;
  }
  .about_us_first {
    display: flex;
    justify-content: center;
  }
  .about_us_first > img {
    width: 90%;
    height: 90%;
  }

  .about_us_second {
    margin-top: 0px;
    display: flex;
    justify-content: center;
  }

  .about_us_second > img {
    width: 90%;
    height: 90%;
  }
  .about_section_text_wrapper_second {
    margin-top: 180px;
    margin-bottom: 120px;
  }
  .about_section_text_wrapper_third {
    margin-top: 180px;
    margin-bottom: 120px;
  }

  .about_yellow_line {
    margin-top: 40px;
  }
  .about_elements_img > div {
    display: none;
  }
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
  .about_section_hero_main_wrapper {
    height: 95vh;
  }
  .about_hero_img_sec {
    position: absolute;
    top: 20px;
    left: 15%;
  }
  .abput_hero_bg {
    position: absolute;
    top: 80px;
    left: 25%;
  }

  /* 
=========================== */

  .about_section_text_wrapper {
    margin-top: 120px;
    margin-bottom: 1px;
  }
  .about_us_first {
    display: flex;
    justify-content: center;
  }
  .about_us_first > img {
    width: 80%;
    height: 80%;
  }

  .about_us_second {
    margin-top: 0px;
    display: flex;
    justify-content: center;
  }

  .about_us_second > img {
    width: 80%;
    height: 80%;
  }
  .about_section_text_wrapper_second {
    margin-top: 140px;
    margin-bottom: 120px;
  }
  .about_section_text_wrapper_third {
    margin-top: 140px;
    margin-bottom: 120px;
  }

  .about_yellow_line {
    margin-top: 40px;
  }
  .about_elements_img > div {
    display: none;
  }
}

@media only screen and (max-width: 576px) and (min-width: 470px) {
  .about_section_hero_main_wrapper {
    height: 95vh;
  }
  .about_hero_img_sec {
    position: absolute;
    top: 20px;
    left: 0%;
  }
  .abput_hero_bg {
    position: absolute;
    top: 80px;
    left: 12%;
  }

  .about_section_text_wrapper {
    margin-top: 120px;
    margin-bottom: 1px;
    margin-left: 24px;
    margin-right: 24px;
  }
  .about_us_first {
    display: flex;
    justify-content: center;
  }
  .about_us_first > img {
    width: 80%;
    height: 80%;
  }

  .about_us_second {
    margin-top: 0px;

    display: flex;
    justify-content: center;
  }

  .about_us_second > img {
    width: 80%;
    height: 80%;
  }
  .about_section_text_wrapper_second {
    margin-top: 140px;
    margin-bottom: 120px;
    margin-left: 24px;
    margin-right: 24px;
  }
  .about_section_text_wrapper_third {
    margin-top: 140px;
    margin-bottom: 120px;
    margin-left: 24px;
    margin-right: 24px;
  }

  .about_yellow_line {
    margin-top: 40px;
  }
  .about_elements_img > div {
    display: none;
  }

  .about_us_elements_haeding {
    margin-left: 24px;
    margin-right: 24px;
  }

  .about_us_elements_para {
    margin-left: 24px;
    margin-right: 24px;
  }
  .about_us_first {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 470px) and (min-width: 270px) {
  .about_section_hero_main_wrapper {
    height: 95vh;
  }
  .about_hero_img_sec {
    position: absolute;
    top: 10px;
    left: 0%;
  }
  .abput_hero_bg {
    position: absolute;
    top: 10px;
    left: 20%;
  }
  .about_section_hero_main_wrapper {
    padding-top: 120px;
  }

  .abput_hero_bg > img {
    width: 80%;
    height: 80%;
  }
  .about_hero_img_sec > img {
    width: 80%;
    height: 80%;
  }

  .about_section_text_wrapper {
    margin-top: 120px;
    margin-bottom: 1px;
    margin-left: 24px;
    margin-right: 24px;
  }
  .about_us_first {
    display: flex;
    justify-content: center;
  }
  .about_us_first > img {
    width: 80%;
    height: 80%;
  }

  .about_us_second {
    margin-top: 0px;

    display: flex;
    justify-content: center;
  }

  .about_section_text_wrapper_second {
    margin-top: 140px;
    margin-bottom: 120px;
    margin-left: 24px;
    margin-right: 24px;
  }
  .about_section_text_wrapper_third {
    margin-top: 140px;
    margin-bottom: 120px;
    margin-left: 24px;
    margin-right: 24px;
  }

  .about_yellow_line {
    margin-top: 40px;
  }
  .about_elements_img > div {
    display: none;
  }

  .about_us_elements_haeding {
    margin-left: 24px;
    margin-right: 24px;
  }

  .about_us_elements_para {
    margin-left: 24px;
    margin-right: 24px;
  }

  .about_section_hero_section > h1 {
    font-family: Ghost Clan;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 73px;
    background: linear-gradient(180deg, #fedd22 5.56%, #f4913f 89.29%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .about_us_first {
    margin-top: 24px;
  }
  .about_us_values_sec-para {
    padding-left: 16px;
    padding-right: 16px;
  }
  .about_elements_img {
    margin-bottom: 24px;
    margin-top: 16px;
  }
  .ceo_message_about {
    margin-top: 32px;
  }
}

.about_footer {
  margin-top: 80px;
}

.careers_main_wrapper {
  background: #eeeeee;
}

.career_msg_team_ {
  margin-top: 0px;
  margin-bottom: 40px;
}
.career_msg_team_ > h3 {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 42px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 0px !important;
}

.career_msg_team {
  margin-top: 163px;
  margin-bottom: 40px;
}
.career_msg_team > h3 {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 42px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 0px !important;
}
.career_items {
  background: #ffffff;
  border-radius: 4px;
  padding-bottom: 40px;
  margin-bottom: 80px;
}
.careers_items_list {
  margin-top: 32px;
  margin-bottom: 64px;
}

.careers_items_list > p {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  font-feature-settings: "salt" on, "liga" off;

  color: #000000;
}
.caraousel_item_commas {
  margin-right: 20px;
  margin-top: 8px;
}
.caraousel_item_commas > h3 {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 72px;
  line-height: 98px;
  /* identical to box height, or 136% */

  font-feature-settings: "salt" on, "liga" off;

  /* S Orange */

  color: #ffc000;
}

.selction_options_width {
  width: 50%;
}

.careers_items_list_heading > h3 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  /* or 136% */

  display: flex;
  align-items: center;

  color: #000000;
}
.careers_items_list_text > h4 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  /* or 136% */

  display: flex;
  align-items: center;

  color: #000000;
}

.careers_items_list_img > img {
  margin-right: 16px;
}

.career_culture_wrapper {
  padding-top: 80px;
}

.career_our_culture_heading > h6 {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal !important;
  font-size: 48px !important;
  line-height: 42px !important;
  /* identical to box height */

  text-transform: uppercase;

  color: #000000;
  margin-bottom: 0 !important;
}

.career_our_culture_text > p {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 35px;
  color: #000000;
}

.career_employee_benefite {
  margin-top: 84px;
  margin-bottom: 64px;
}

.craeer_employee_wellBeing > p {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 35px;

  color: #000000;
  margin-bottom: 0px !important;
}
.employee_benefits_wrapper {
  margin-bottom: 64px;
}

.employee_career_well_beign_second {
  margin-bottom: 80px;
}

.career_open_position_wrapper > h4 {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 42px;

  text-align: center;
  text-transform: uppercase;

  color: #000000;
}

.Employee_Benifits_Careeer > h4 {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 42px;

  text-align: center;
  text-transform: uppercase;

  color: #000000;
}

.career_open_position_wrapper {
  margin-bottom: 64px;
}

.career_position_head > h4 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  /* identical to box height, or 125% */

  text-align: center;

  color: #000000;
}

.career_position_para > p {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 35px;
  /* or 175% */

  text-align: center;

  color: #000000;
}

.career_position_btn > button {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;

  color: #000000;

  background: #ffc000;
  /* S Orange */

  border: 1px solid #ffc000;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 152px 16px 152px;
}

.job_openings_wrapper {
  margin-bottom: 64px;
}

.career_join_form {
  margin-bottom: 64px;
}

.career_join_form > h3 {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 42px;
  /* identical to box height */

  text-transform: uppercase;

  color: #000000;
  margin-bottom: 0px !important;
}

.career_jojn_form > input:-ms-input-placeholder {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.5px;

  color: #8e8e8e;
}

.career_jojn_form > input::placeholder {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.5px;

  color: #8e8e8e;
}
.career_jojn_form__ > input:-ms-input-placeholder {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.5px;

  color: #8e8e8e;
}
.career_jojn_form__ > input::placeholder {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.5px;

  color: #8e8e8e;
}

.career_jojn_form_ > input:-ms-input-placeholder {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.5px;

  color: #8e8e8e;
}

.career_jojn_form_ > input::placeholder {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.5px;

  color: #8e8e8e;
}

.career_jojn_form > input {
  width: 100%;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 2px;
  height: 48px;
  padding-left: 16px;
}

.career_jojn_form__ > input {
  width: 92%;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 2px;
  height: 48px;
  padding-left: 16px;
}

.career_jojn_form_ > div {
  width: 92%;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 2px;
  height: 104px;
}

.career_jojn_form {
  margin-right: 8px;
  margin-left: 8px;
  margin-bottom: 0px;
}

.career_jojn_form_ {
  margin-bottom: 0px;
}

.career_jojn_form > select {
  -webkit-appearance: none;
          appearance: none;
  outline: 0;
  border: 0;
  box-shadow: none;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: var(--darkgray);
  background-image: none;
  cursor: pointer;
  width: 100%;
  height: 48px;
}

.select {
  position: relative;
  display: flex;
  overflow: hidden;
}

.select > select {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.5px;

  color: #8e8e8e;
  padding-left: 12px;
}
.select::after {
  content: url(/static/media/arrow.70f13b69.svg);
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;

  transition: 0.25s all ease;
  pointer-events: none;
}

.careers_upload_file > div > input {
  position: relative;
  color: transparent;
  opacity: 0;
  width: 100%;
  z-index: 22;
}

.careers_upload_file > div > div {
  position: absolute;
  top: 40%;
  left: 40%;
  z-index: 1;
}

.careers_upload_file > div > div > h1 > h3 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.5px;

  /* S Orange */

  color: #ffc000;
  margin-right: 8px;
}

.careers_upload_file > div > div > h1 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.5px;

  /* S Orange */

  color: #8e8e8e;
}

.content_main_last_div {
  position: relative;
}

#file-upload-button {
  color: #000000;
  border: none !important;
  outline: none !important;
  background: transparent !important;
}

.career_last_inpurt___ {
  margin-bottom: 32px;
}
.career_jojn_form__ > button {
  width: 92%;
  background: #ffc000;
  border-radius: 4px;
  border: none;
  height: 48px;

  font-family: Myriad Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  letter-spacing: 1.25px;
  text-transform: uppercase;

  color: #000000;
}

.career_last_inpurt___ {
  margin-bottom: 32px;
}
.career_culture_img > img {
  width: 100%;
  height: 100%;
}

.erropr_mag {
  margin-left: 8px;
}

.erropr_mag_ {
  margin-left: 40px;
}
.file_mag_ > p {
  margin-left: -20px;
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.5px;

  /* S Orange */

  color: #8e8e8e;
}
@media only screen and (max-width: 992px) and (min-width: 768px) {
  .reward_wrapper {
    margin-bottom: 24px;
  }
  .career_jojn_form_ {
    margin-left: 16px;
  }
  .career_jojn_form_ > div {
    width: 100%;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 2px;
    height: 104px;
  }

  .career_jojn_form__ {
    margin-left: 16px;
  }
  .career_jojn_form__ > input {
    width: 100%;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 2px;
    height: 48px;
  }

  .career_jojn_form__ > button {
    width: 100%;
  }
  .careers_upload_file > div > div {
    position: absolute;
    top: 40%;
    left: 36%;
    z-index: 1;
  }
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
  .reward_wrapper {
    margin-bottom: 24px;
  }
  .career_jojn_form_ {
    margin-left: 16px;
  }
  .career_jojn_form_ > div {
    width: 100%;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 2px;
    height: 104px;
  }

  .career_jojn_form__ {
    margin-left: 16px;
  }
  .career_jojn_form__ > input {
    width: 100%;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 2px;
    height: 48px;
  }

  .career_jojn_form__ > button {
    width: 100%;
  }

  .careers_upload_file > div > div {
    position: absolute;
    top: 40%;
    left: 30%;
    z-index: 1;
  }
}

@media only screen and (max-width: 576px) and (min-width: 470px) {
  .career_items {
    padding-left: 16px;
    padding-right: 16px;
  }
  .reward_wrapper {
    margin-bottom: 24px;
  }
  .career_jojn_form_ {
    margin-left: 0px;
  }
  .career_jojn_form_ > div {
    width: 100%;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 2px;
    height: 104px;
  }

  .career_jojn_form__ {
    margin-left: 0px;
  }
  .career_jojn_form__ > input {
    width: 100%;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 2px;
    height: 48px;
  }

  .career_jojn_form__ > button {
    width: 100%;
  }

  .careers_upload_file > div > div {
    position: absolute;
    top: 40%;
    left: 30%;
    z-index: 1;
  }
  .career_jojn_form {
    margin-left: 0px;
  }

  .career_our_culture_heading > h6 {
    font-family: Ghost Clan;
    font-style: normal;
    font-weight: normal !important;
    font-size: 42px !important;
    line-height: 42px !important;
    /* identical to box height */

    text-transform: uppercase;

    color: #000000;
    margin-bottom: 0 !important;
  }

  .employee_benefits_wrapper {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 470px) and (min-width: 270px) {
  .career_items {
    padding-left: 16px;
    padding-right: 16px;
  }
  .career_our_culture_heading > h6 {
    font-family: Ghost Clan;
    font-style: normal;
    font-weight: normal !important;
    font-size: 42px !important;
    line-height: 42px !important;
    /* identical to box height */

    text-transform: uppercase;

    color: #000000;
    margin-bottom: 0 !important;
  }
  .reward_wrapper {
    margin-bottom: 24px;
  }
  .career_jojn_form_ {
    margin-left: 0px;
  }
  .career_jojn_form_ > div {
    width: 100%;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 2px;
    height: 104px;
  }

  .career_jojn_form__ {
    margin-left: 0px;
  }
  .career_jojn_form__ > input {
    width: 100%;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 2px;
    height: 48px;
  }

  .career_jojn_form__ > button {
    width: 100%;
  }

  .careers_upload_file > div > div {
    position: absolute;
    top: 40%;
    left: 20%;
    z-index: 1;
  }
  .career_jojn_form {
    margin-left: 0px;
  }

  .erropr_mag {
    margin-left: 0px;
  }

  .erropr_mag_ {
    margin-left: 0px;
  }
  .career_jojn_form {
    margin-bottom: 16px;
  }

  .employee_benefits_wrapper {
    margin-bottom: 0px;
  }
}

.blog_main_heading {
  margin-top: 64px;
}
.blog_main_heading > h4 {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal;
  font-size: 80px;
  line-height: 69px;

  text-transform: uppercase;

  color: #000000;
}
.blog_main_para > p {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 35px;
  /* or 159% */

  text-align: justify;

  color: #000000;
}

.blog_main_img {
  margin-top: 64px;
  margin-bottom: 64px;
}

.blog_main_img > img {
  width: 100%;
}

.blog_main_img_ {
  margin-top: 64px;
}

.blog_main_img_ > img {
  width: 100%;
}
.blog_com_main_wrapper {
  margin-bottom: 100px;
}

.boplg_main_image > img {
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 470px) and (min-width: 270px) {
  .blog_main_heading > h4 {
    font-family: Ghost Clan;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 40px;

    text-transform: uppercase;

    color: #000000;
  }
  .blog_main_para{
    padding-left: 8px;
    padding-right: 8px;
  }
}

.call_background_img {
  background-image: url("https://aurilian.s3.us-east-2.amazonaws.com/compresspng+(1)/call_bg-min.png");
  background-color: #cccccc; /* Used if the image is unavailable */
  height: calc(100vh - 82px); /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  z-index: -1;
}

.call_coming_soon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
}

.call_coming_soon > h3 {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal;
  font-size: 96px;
  line-height: 83px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  background: linear-gradient(180deg, #fedd22 5.56%, #f4913f 89.29%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
  .call_coming_soon {
    text-align: center;
  }
}

@media only screen and (max-width: 576px) and (min-width: 470px) {
  .call_coming_soon {
    text-align: center;
  }
}
@media only screen and (max-width: 470px) and (min-width: 270px) {
  .call_coming_soon {
    text-align: center;
  }

  .call_coming_soon > h3 {
    font-family: Ghost Clan;
    font-style: normal;
    font-weight: normal;
    font-size: 60px;
    line-height: 50px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    background: linear-gradient(180deg, #fedd22 5.56%, #f4913f 89.29%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.contact-section {
  margin-bottom: 163px;
}
.contact_get_in_touch > h4 {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 42px;
  /* identical to box height */

  text-transform: uppercase;

  color: #000000;
  margin-bottom: 0px !important;
}
.contact_get_in_touch {
  margin-bottom: 12px;
}

.contact_get_in_touch_para > h5 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 24px;
  /* identical to box height, or 109% */

  letter-spacing: 0.15px;

  color: #000000;
  margin-bottom: 0px !important;
}

.contact_get_in_touch_para {
  margin-bottom: 40px;
}

.contact_section_input > input {
  width: 100%;
  height: 48px;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 16px;
}
.contact_section_input > textarea {
  width: 100%;

  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 16px;
  padding-top: 12px;
}
.contact_section_input {
  margin-top: 32px;
}
.contact_section_input > input:-ms-input-placeholder, .contact_section_input > textarea:-ms-input-placeholder {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.5px;

  color: #8e8e8e;

  /* padding-top: 12px; */
}
.contact_section_input > input::placeholder,
.contact_section_input > textarea::placeholder {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.5px;

  color: #8e8e8e;

  /* padding-top: 12px; */
}

.contact_section_input > button {
  width: 100%;
  /* height: 48px; */
  background: #ffc000;
  border-radius: 4px;
  border: none;

  font-family: Myriad Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  letter-spacing: 1.25px;
  text-transform: uppercase;

  color: #000000;
  padding-top: 16px;
  padding-bottom: 16px;
}

.contact_input_form {
  margin-bottom: 101px;
}

.conatct_details_heading {
  margin-bottom: 12px;
}

.conatct_details_heading > h4 {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 42px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #000000;
  margin-bottom: 0px !important;
}
.conatct_details_heading_para {
  margin-bottom: 40px;
}
.conatct_details_heading_para > h3 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 24px;
  /* identical to box height, or 109% */

  letter-spacing: 0.15px;

  color: #000000;
  margin-bottom: 0px !important;
}
.contact_us_icon {
  margin-bottom: 14px;
}
.contact_us_icon > img {
  margin-right: 16px;
}

.contact_us_icon > h3 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.5px;

  color: #000000;
}

.error-field1,
.error-field2,
.error-field3,
.error-field4 {
  border-color: #b00020 !important;
}

.error-field3 {
  color: #b00020;
}

/* disabel button styling */

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc !important;
  cursor: not-allowed;
  color: #666666;
}

.spinner-border {
  width: 20px !important;
  height: 20px !important;
  margin-right: 11px !important;
}
@media only screen and (max-width: 470px) and (min-width: 270px) {
  .contact_get_in_touch {
    margin-top: 24px;
  }
}

