.contact-section {
  margin-bottom: 163px;
}
.contact_get_in_touch > h4 {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 42px;
  /* identical to box height */

  text-transform: uppercase;

  color: #000000;
  margin-bottom: 0px !important;
}
.contact_get_in_touch {
  margin-bottom: 12px;
}

.contact_get_in_touch_para > h5 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 24px;
  /* identical to box height, or 109% */

  letter-spacing: 0.15px;

  color: #000000;
  margin-bottom: 0px !important;
}

.contact_get_in_touch_para {
  margin-bottom: 40px;
}

.contact_section_input > input {
  width: 100%;
  height: 48px;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 16px;
}
.contact_section_input > textarea {
  width: 100%;

  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 16px;
  padding-top: 12px;
}
.contact_section_input {
  margin-top: 32px;
}
.contact_section_input > input::placeholder,
.contact_section_input > textarea::placeholder {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.5px;

  color: #8e8e8e;

  /* padding-top: 12px; */
}

.contact_section_input > button {
  width: 100%;
  /* height: 48px; */
  background: #ffc000;
  border-radius: 4px;
  border: none;

  font-family: Myriad Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  letter-spacing: 1.25px;
  text-transform: uppercase;

  color: #000000;
  padding-top: 16px;
  padding-bottom: 16px;
}

.contact_input_form {
  margin-bottom: 101px;
}

.conatct_details_heading {
  margin-bottom: 12px;
}

.conatct_details_heading > h4 {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 42px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #000000;
  margin-bottom: 0px !important;
}
.conatct_details_heading_para {
  margin-bottom: 40px;
}
.conatct_details_heading_para > h3 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 24px;
  /* identical to box height, or 109% */

  letter-spacing: 0.15px;

  color: #000000;
  margin-bottom: 0px !important;
}
.contact_us_icon {
  margin-bottom: 14px;
}
.contact_us_icon > img {
  margin-right: 16px;
}

.contact_us_icon > h3 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.5px;

  color: #000000;
}

.error-field1,
.error-field2,
.error-field3,
.error-field4 {
  border-color: #b00020 !important;
}

.error-field3 {
  color: #b00020;
}

/* disabel button styling */

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc !important;
  cursor: not-allowed;
  color: #666666;
}

.spinner-border {
  width: 20px !important;
  height: 20px !important;
  margin-right: 11px !important;
}
@media only screen and (max-width: 470px) and (min-width: 270px) {
  .contact_get_in_touch {
    margin-top: 24px;
  }
}
