@import url("https://fonts.googleapis.com/css2?family=Cormorant&family=Poppins&display=swap");

.careers_main_wrapper {
  background: #eeeeee;
}

.career_msg_team_ {
  margin-top: 0px;
  margin-bottom: 40px;
}
.career_msg_team_ > h3 {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 42px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 0px !important;
}

.career_msg_team {
  margin-top: 163px;
  margin-bottom: 40px;
}
.career_msg_team > h3 {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 42px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 0px !important;
}
.career_items {
  background: #ffffff;
  border-radius: 4px;
  padding-bottom: 40px;
  margin-bottom: 80px;
}
.careers_items_list {
  margin-top: 32px;
  margin-bottom: 64px;
}

.careers_items_list > p {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  font-feature-settings: "salt" on, "liga" off;

  color: #000000;
}
.caraousel_item_commas {
  margin-right: 20px;
  margin-top: 8px;
}
.caraousel_item_commas > h3 {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 72px;
  line-height: 98px;
  /* identical to box height, or 136% */

  font-feature-settings: "salt" on, "liga" off;

  /* S Orange */

  color: #ffc000;
}

.selction_options_width {
  width: 50%;
}

.careers_items_list_heading > h3 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  /* or 136% */

  display: flex;
  align-items: center;

  color: #000000;
}
.careers_items_list_text > h4 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  /* or 136% */

  display: flex;
  align-items: center;

  color: #000000;
}

.careers_items_list_img > img {
  margin-right: 16px;
}

.career_culture_wrapper {
  padding-top: 80px;
}

.career_our_culture_heading > h6 {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal !important;
  font-size: 48px !important;
  line-height: 42px !important;
  /* identical to box height */

  text-transform: uppercase;

  color: #000000;
  margin-bottom: 0 !important;
}

.career_our_culture_text > p {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 35px;
  color: #000000;
}

.career_employee_benefite {
  margin-top: 84px;
  margin-bottom: 64px;
}

.craeer_employee_wellBeing > p {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 35px;

  color: #000000;
  margin-bottom: 0px !important;
}
.employee_benefits_wrapper {
  margin-bottom: 64px;
}

.employee_career_well_beign_second {
  margin-bottom: 80px;
}

.career_open_position_wrapper > h4 {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 42px;

  text-align: center;
  text-transform: uppercase;

  color: #000000;
}

.Employee_Benifits_Careeer > h4 {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 42px;

  text-align: center;
  text-transform: uppercase;

  color: #000000;
}

.career_open_position_wrapper {
  margin-bottom: 64px;
}

.career_position_head > h4 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  /* identical to box height, or 125% */

  text-align: center;

  color: #000000;
}

.career_position_para > p {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 35px;
  /* or 175% */

  text-align: center;

  color: #000000;
}

.career_position_btn > button {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;

  color: #000000;

  background: #ffc000;
  /* S Orange */

  border: 1px solid #ffc000;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 152px 16px 152px;
}

.job_openings_wrapper {
  margin-bottom: 64px;
}

.career_join_form {
  margin-bottom: 64px;
}

.career_join_form > h3 {
  font-family: Ghost Clan;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 42px;
  /* identical to box height */

  text-transform: uppercase;

  color: #000000;
  margin-bottom: 0px !important;
}

.career_jojn_form > input::placeholder {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.5px;

  color: #8e8e8e;
}
.career_jojn_form__ > input::placeholder {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.5px;

  color: #8e8e8e;
}

.career_jojn_form_ > input::placeholder {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.5px;

  color: #8e8e8e;
}

.career_jojn_form > input {
  width: 100%;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 2px;
  height: 48px;
  padding-left: 16px;
}

.career_jojn_form__ > input {
  width: 92%;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 2px;
  height: 48px;
  padding-left: 16px;
}

.career_jojn_form_ > div {
  width: 92%;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 2px;
  height: 104px;
}

.career_jojn_form {
  margin-right: 8px;
  margin-left: 8px;
  margin-bottom: 0px;
}

.career_jojn_form_ {
  margin-bottom: 0px;
}

.career_jojn_form > select {
  appearance: none;
  outline: 0;
  border: 0;
  box-shadow: none;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: var(--darkgray);
  background-image: none;
  cursor: pointer;
  width: 100%;
  height: 48px;
}

.select {
  position: relative;
  display: flex;
  overflow: hidden;
}

.select > select {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.5px;

  color: #8e8e8e;
  padding-left: 12px;
}
.select::after {
  content: url("../Resources/Images/Slider/arrow.svg");
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;

  transition: 0.25s all ease;
  pointer-events: none;
}

.careers_upload_file > div > input {
  position: relative;
  color: transparent;
  opacity: 0;
  width: 100%;
  z-index: 22;
}

.careers_upload_file > div > div {
  position: absolute;
  top: 40%;
  left: 40%;
  z-index: 1;
}

.careers_upload_file > div > div > h1 > h3 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.5px;

  /* S Orange */

  color: #ffc000;
  margin-right: 8px;
}

.careers_upload_file > div > div > h1 {
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.5px;

  /* S Orange */

  color: #8e8e8e;
}

.content_main_last_div {
  position: relative;
}

#file-upload-button {
  color: #000000;
  border: none !important;
  outline: none !important;
  background: transparent !important;
}

.career_last_inpurt___ {
  margin-bottom: 32px;
}
.career_jojn_form__ > button {
  width: 92%;
  background: #ffc000;
  border-radius: 4px;
  border: none;
  height: 48px;

  font-family: Myriad Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  letter-spacing: 1.25px;
  text-transform: uppercase;

  color: #000000;
}

.career_last_inpurt___ {
  margin-bottom: 32px;
}
.career_culture_img > img {
  width: 100%;
  height: 100%;
}

.erropr_mag {
  margin-left: 8px;
}

.erropr_mag_ {
  margin-left: 40px;
}
.file_mag_ > p {
  margin-left: -20px;
  font-family: Myriad Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.5px;

  /* S Orange */

  color: #8e8e8e;
}
@media only screen and (max-width: 992px) and (min-width: 768px) {
  .reward_wrapper {
    margin-bottom: 24px;
  }
  .career_jojn_form_ {
    margin-left: 16px;
  }
  .career_jojn_form_ > div {
    width: 100%;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 2px;
    height: 104px;
  }

  .career_jojn_form__ {
    margin-left: 16px;
  }
  .career_jojn_form__ > input {
    width: 100%;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 2px;
    height: 48px;
  }

  .career_jojn_form__ > button {
    width: 100%;
  }
  .careers_upload_file > div > div {
    position: absolute;
    top: 40%;
    left: 36%;
    z-index: 1;
  }
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
  .reward_wrapper {
    margin-bottom: 24px;
  }
  .career_jojn_form_ {
    margin-left: 16px;
  }
  .career_jojn_form_ > div {
    width: 100%;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 2px;
    height: 104px;
  }

  .career_jojn_form__ {
    margin-left: 16px;
  }
  .career_jojn_form__ > input {
    width: 100%;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 2px;
    height: 48px;
  }

  .career_jojn_form__ > button {
    width: 100%;
  }

  .careers_upload_file > div > div {
    position: absolute;
    top: 40%;
    left: 30%;
    z-index: 1;
  }
}

@media only screen and (max-width: 576px) and (min-width: 470px) {
  .career_items {
    padding-left: 16px;
    padding-right: 16px;
  }
  .reward_wrapper {
    margin-bottom: 24px;
  }
  .career_jojn_form_ {
    margin-left: 0px;
  }
  .career_jojn_form_ > div {
    width: 100%;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 2px;
    height: 104px;
  }

  .career_jojn_form__ {
    margin-left: 0px;
  }
  .career_jojn_form__ > input {
    width: 100%;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 2px;
    height: 48px;
  }

  .career_jojn_form__ > button {
    width: 100%;
  }

  .careers_upload_file > div > div {
    position: absolute;
    top: 40%;
    left: 30%;
    z-index: 1;
  }
  .career_jojn_form {
    margin-left: 0px;
  }

  .career_our_culture_heading > h6 {
    font-family: Ghost Clan;
    font-style: normal;
    font-weight: normal !important;
    font-size: 42px !important;
    line-height: 42px !important;
    /* identical to box height */

    text-transform: uppercase;

    color: #000000;
    margin-bottom: 0 !important;
  }

  .employee_benefits_wrapper {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 470px) and (min-width: 270px) {
  .career_items {
    padding-left: 16px;
    padding-right: 16px;
  }
  .career_our_culture_heading > h6 {
    font-family: Ghost Clan;
    font-style: normal;
    font-weight: normal !important;
    font-size: 42px !important;
    line-height: 42px !important;
    /* identical to box height */

    text-transform: uppercase;

    color: #000000;
    margin-bottom: 0 !important;
  }
  .reward_wrapper {
    margin-bottom: 24px;
  }
  .career_jojn_form_ {
    margin-left: 0px;
  }
  .career_jojn_form_ > div {
    width: 100%;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 2px;
    height: 104px;
  }

  .career_jojn_form__ {
    margin-left: 0px;
  }
  .career_jojn_form__ > input {
    width: 100%;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 2px;
    height: 48px;
  }

  .career_jojn_form__ > button {
    width: 100%;
  }

  .careers_upload_file > div > div {
    position: absolute;
    top: 40%;
    left: 20%;
    z-index: 1;
  }
  .career_jojn_form {
    margin-left: 0px;
  }

  .erropr_mag {
    margin-left: 0px;
  }

  .erropr_mag_ {
    margin-left: 0px;
  }
  .career_jojn_form {
    margin-bottom: 16px;
  }

  .employee_benefits_wrapper {
    margin-bottom: 0px;
  }
}
