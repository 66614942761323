.Hompage-slider-wrapper {
  width: 99vw;
  height: 88vh;
  overflow: hidden;
  background: #000000;
}

.slider-Image-wrapper > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  position: relative;
}
.Hompage-slider-container {
  position: relative;
  left: 0;
  top: 0;
}
.prev {
  cursor: pointer;
}
.prev > button {
  background-color: transparent;
  border: none;
}
.next {
  cursor: pointer;
}

.next > button {
  background-color: transparent;
  border: none;
}
.Homepage_arrows {
  width: 100vw;
  position: absolute;
  top: 48%;
  z-index: 10;
}

@media only screen and (max-width: 768px) {
  .slider-Image-wrapper > img {
    width: 100%;
    height: 30vh;
    object-fit: cover;

    overflow: hidden;
  }
  .Hompage-slider-wrapper {
    width: 100%;
    height: 30vh;
    overflow: hidden;
    background: #000000;
  }
}
